import React, { useState, useEffect, useRef } from "react"
import gsap from "gsap"
import { Power4, Expo } from "gsap"

import RegisterButton from "../shared/registerButton"
import { StaticImage } from "gatsby-plugin-image"
import KlassenSection from "./klassenSection"

import "./heroSection.css"

const HeroSection = (): JSX.Element => {
  // Use Effect für ScrollAnimationen
  useEffect(() => {
    //Text und Button Anim
    gsap
      .timeline({
        delay: 0.7,
      })
      .from("#bild1", {
        opacity: 0,
        duration: 1,
        y: 300,
        ease: Expo.easeInOut,
      })
      .from(
        "#heading1",
        {
          x: -800,
          opacity: 0,
          ease: Expo.easeInOut,
          duration: 1,
        },
        "-=0.5"
      )
      .from(
        "#text1",
        {
          opacity: 0,
        },
        "-=0.3"
      )
      .from("#button1", {
        opacity: 0,
      })
  }, [])

  return (
    <div className="hero-section section-vertical-spacing z-10 sm:relative  md:flex md:flex-wrap container-fluid snap-y snap-mandatory  relative">
      {/* Title */}
      <div className="h-8 sm:h-auto z-10 relative pb-32 sm:pb-0  w-full">
        <h1 id="heading1" className="sm:w-9/12 xll:w-2/3">
          Easy zum Füherschein in Flensburg Tarup
          <br className="hidden sm:inline" /> und Satrup
        </h1>
      </div>
      {/* Bild - Wrapper / Spalte 2  */}
      <div className="w-full h-64 xs:h-80  img-wrapper order-1 sm:pl-8 sm:overflow-visible sm:absolute sm:right-0 sm:top-60 sm:w-7/12 hero-img-wrapper xl:top-56 ">
        {/* Bild  */}
        <div
          id="bild1"
          className="w-5/6 sm:w-full h-64 xs:h-80 overflow-hidden img-wrapper absolute sm:relative sm:h-full  right-0 bg-slate-200 flex "
        >
          <StaticImage
            className="object-fill h-96  sm:h-full"
            src="../../images/heroSection2.png"
            alt="Achims Fahrschule Foto Fahrschüler"
          />
        </div>
      </div>
      {/* Spalte 1 */}
      <div className="sm:w-5/12 xxl:w-4/12 pb-12 xl:pb-16 xxxl:pb-42">
        {/* Text */}
        <p id="text1" className="md:pr-8">
          Einfach und unkompliziert zum Führerschein. Das ist seit mittlerweile
          über 20 Jahren erfolgreich unsere Mission. Achims Fahrschule ist auf
          die Vergabe von Auto sowie Motorrad Führerscheinen spezialisiert. Das
          macht uns zum entscheidenden Partner für alle Führerscheine der Klasse
          B und A.
        </p>
        {/* Button */}
        <RegisterButton id="button1">Jetzt anmelden</RegisterButton>
      </div>
      <KlassenSection />
    </div>
  )
}

export default HeroSection
