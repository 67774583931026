import React from "react"
import { useEffect } from "react"

import RegisterButton from "../shared/registerButton"
import { StaticImage } from "gatsby-plugin-image"

import useSiteContactData from "../../helper/contactData"
import gsap from "gsap"

const StandorteSection = (): JSX.Element => {
  const contactData = useSiteContactData()

  // Use Effect für ScrollAnimationen
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#standorte-section",
          start: "top center",
        },
      })
      .from("#stand-title", {
        y: -100,
        opacity: 0,
        ease: "power4.out",
      })
      .from(
        ".stand-img",
        {
          scale: 0,
          ease: "power4.out",
        },
        "-=0.3"
      )
      .from(
        ".stand-h3",
        {
          x: -100,
          opacity: 0,
          ease: "power4.out",
        },
        "-=0.3"
      )
      .from(
        ".stand-info",
        {
          y: 100,
          opacity: 0,
          ease: "power4.out",
        },
        "-=0.3"
      )
  })

  return (
    <div
      id="standorte-section"
      className="container-fluid section-vertical-spacing lg:pt-0 xl:pt-0 xxl:pt-0 md:flex md:flex-wrap snap-center"
    >
      <h2 id="stand-title" className="lg:w-2/3">
        An diesen Standorten findest du unsere Fahrschule:
      </h2>
      {/* Reihe */}
      <div className="flex flex-col space-y-8 sm:flex-row sm:flex-nowrap sm:space-x-8 sm:space-y-0 lg:space-x-16">
        {/* Spalte 1 */}
        <div className="w-full sm:w-1/2">
          {/* Ueberschrift */}
          <div className="h-11 sm:h-14 md:h-16 lg:h-20 xl:h-28 xll:h-28 xxl:h-32 overflow-y-visible z-10 relative">
            <h3 className="text-secondary mb-0 stand-h3">
              Fahrschule
              <br />
              in {contactData.ORT1}
            </h3>
          </div>
          {/* Bild */}
          <div className="img-wrapper -z-10 pl-8 xl:pl-16 ">
            <StaticImage
              className="stand-img"
              src="../../images/AchimsFahrschule-Standort-Tarup-dsktp.jpg"
              alt="Foto Standort Achims Fahrschule Tarup"
            ></StaticImage>
            {/* Reihe Adresse */}
            <div className="flex flex-nowrap space-x-8 sm:space-x-4 lg:space-x-16 mt-3  sm:mt-4 stand-info">
              {/* Spalte 1 */}
              <div>
                <h4>Adresse</h4>
                <ul>
                  <li>{contactData.STRASSE1}</li>
                  <li>
                    {contactData.PLZ1} {contactData.ORT1}
                  </li>
                </ul>
              </div>
              {/* Spalte 2 */}
              <div>
                <h4>Öffnungszeiten</h4>
                <ul>
                  <li>{contactData.OPENING1A}</li>
                  <li>{contactData.OPENING1B}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Spalte 2 */}
        <div className="w-full sm:w-1/2">
          {/* Ueberschrift */}
          <div className="h-11 sm:h-14 md:h-16 lg:h-20 xl:h-28  xll:h-28 xxl:h-32  overflow-y-visible z-10 relative">
            <h3 className="text-secondary mb-0 stand-h3">
              Fahrschule
              <br />
              in {contactData.ORT2}
            </h3>
          </div>
          {/* Bild */}
          <div className="img-wrapper -z-10 pl-8 xl:pl-16">
            <StaticImage
              className="stand-img"
              src="../../images/AchimsFahrschule-Standort-Satrup-dsktp.jpg"
              alt="Foto Standort Achims Fahrschule Satrup"
            ></StaticImage>
            {/* Reihe Adresse */}
            <div className="flex flex-nowrap space-x-8 sm:space-x-4 lg:space-x-16 mt-3  sm:mt-4 stand-info">
              {/* Spalte 1 */}
              <div>
                <h4>Adresse</h4>
                <ul>
                  <li>{contactData.STRASSE2}</li>
                  <li>
                    {contactData.PLZ2} {contactData.ORT2}
                  </li>
                </ul>
              </div>
              {/* Spalte 2 */}
              <div>
                <h4>Öffnungszeiten</h4>
                <ul>
                  <li>{contactData.OPENING2A}</li>
                  <li>{contactData.OPENING2B}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default StandorteSection
